<template>
    <v-card 
    class="width75 mx-auto my-5" 
    elevation="1">
        <v-card-title>{{$t('changePassword')}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-form
            ref="form"
            lazy-validation
            @submit.prevent="updatePassword"
            >
                <v-text-field
                v-model="current_password"
                :label="$t('currPassword')"
                type="password"
                outlined
                :rules="required"
                ></v-text-field>

                <v-text-field
                v-model="new_password"
                :label="$t('newPassword')"
                type="password"
                outlined
                :rules="newPasswordRules.concat(newPasswordMatchingCurrentPasswordRule)"
                ></v-text-field>

                <v-text-field
                v-model="confirm_new_password"
                :label="$t('confirmNewPassword')"
                type="password"
                outlined
                :rules="newPasswordRules.concat(passwordMatchingRule)" 
                ></v-text-field>

                <v-btn
                color="blue-grey" 
                dark 
                block 
                class="ma-1"
                type="submit"
                >
                    {{$t('updateBtn')}}
                </v-btn>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
// importing Api requests
import Api from '@/api/Api';

export default {
    name: 'change-password-card',
    // initializing data
    data: function() {
        return {
            current_password: '',
            new_password: '',
            confirm_new_password: '',
            required: [
                value => !!value || this.$i18n.t('requiredRule'), 
            ],
            newPasswordRules: [
                value => !!value || this.$i18n.t('enterPasswordRule'),
                value => (value && /\d/.test(value)) || this.$i18n.t('oneDigitRule'),
                value => (value && /[A-Z]{1}/.test(value)) || this.$i18n.t('capitalLetterRule'),
                value => (value && /[^A-Za-z0-9]/.test(value)) || this.$i18n.t('specialCharRule'),
                value => (value && value.length > 6 ) || this.$i18n.t('minCharRule'),
            ]
        }
    },
    computed: {
        // validates if new password is the same as the confirm new password
        passwordMatchingRule() {
            return () => this.new_password === this.confirm_new_password || this.$i18n.t('mustMatchRule');
        },
        // validates if new password is the same as the current password
        newPasswordMatchingCurrentPasswordRule() {
            return () => this.current_password !== this.new_password || this.$i18n.t('samePasswordsRule');
        }
    },
    methods: {
        // method that is in charge of calling the api method to update the 
        // customer password
        async updatePassword() {
            if(this.$refs.form.validate()) {
                this.$emit('loading', true);
                try {
                    // appending the passwords to the form data that is going
                    // to be sent to porta
                    let form_data = new FormData();
                    form_data.append('old_password', this.current_password);
                    form_data.append('new_password', this.new_password);

                    // making the request
                    await Api.changeCustomerPassword(
                        this.$store.getters.i_customer,
                        this.$store.getters.token,
                        form_data
                    );

                    this.$emit('loading', false);

                    // showing success component
                    this.$toast.success(this.$t('updatePasswordSuccessMsg'), {
                        timeout: 4000
                    });
                    // reseting the form
                    this.resetForm();
                } catch (error) {
                    this.$emit('loading', false);
                    if (error.response.status === 401) {
                      await this.$store.dispatch('logout');
                    } else if (error.response.status === 433) {
                      await this.$store.dispatch('unverify');
                    } else if (error.response) {
                        this.$emit('error-message', error.response.data.message)
                    } else if (error.request) {
                        this.$emit('error-message', error.request)
                    } else {
                        this.$emit('error-message', error.message)
                    }
                }
            }
        },
        // resets the form
        resetForm(){
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        }
    }
}
</script>