<template>
  <div>
    <v-overlay
    :value="loading"
    >
      <v-progress-circular indeterminate size="100">
      {{$t('loadingBtn')}}
      </v-progress-circular>
    </v-overlay>

    <div v-if="internetService || phoneService || emailService || otherService">
      <v-tabs 
      v-model="tab"
      show-arrows
      centered
      center-active
      background-color="transparent"
      >
        <v-tabs-slider></v-tabs-slider>
          <v-tab href="#internet" v-if="internetService"> {{ $t('internet') }}
          </v-tab>
          <v-tab href="#phone" v-if="phoneService"> {{ $t('phone') }}
          </v-tab>
          <v-tab href="#email" v-if="emailService"> {{ $t('email') }}
          </v-tab>
          <v-tab href="#other" v-if="otherService"> {{ $t('other') }}
          </v-tab>
        </v-tabs>
        <v-tabs-items :value="tab" id="transparent-background">
          <v-tab-item value="internet" v-if="internetService">
            <internet
            v-bind:internetService="internetService"
            @loading="loadingComponent"
            @error-message="showError"
            />
          </v-tab-item>
          <v-tab-item value="phone" v-if="phoneService">
            <phone
            v-bind:phoneService="phoneService"
            @loading="loadingComponent"
            @error-message="showError"
            />
          </v-tab-item>
          <v-tab-item value="email" v-if="emailService">
            <email
            v-bind:emailService="emailService"
            @loading="loadingComponent"
            @error-message="showError"
            />
          </v-tab-item>
          <v-tab-item value="other" v-if="otherService">
            <other
            v-bind:otherService="otherService"
            @loading="loadingComponent"
            @error-message="showError"
            />
          </v-tab-item>
      </v-tabs-items>
    </div>
    <div v-else>
      <v-container class="width50">
        <div class="font20 center mt-8">
          {{$t('noService')}}
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
// importing child components
import Internet from '@/views/Services/Internet.vue';
import Phone from '@/views/Services/Phone.vue';
import Email from '@/views/Services/Email.vue';
import Other from '@/views/Services/Other.vue';
// importing Api requests
import Api from '@/api/Api';

export default {
  name: 'services-',
  // child components that are being displayed
  components: {
    Internet,
    Phone,
    Email,
    Other
  },
  // initializing the data
  data: () => ({
    loading: false,
    internetService: null,
    phoneService: null,
    emailService: null,
    otherService: null,
  }),
  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    }
  },
  // calls the customerAccountList once the components are mounted
  mounted() {
    this.getCustomerAccountList();
  },
  methods: {
    // fetching the customer account list (internet serivce, phone service, etc..)
    async getCustomerAccountList() {
      try {
        this.loadingComponent(true);
        // awaits response to the customer account list 
        let response = await Api.getCustomerAccountList(
          this.$store.getters.i_customer,
          this.$store.getters.token,
        );
        // setting all services with the response data
        this.internetService = response.internetService;
        this.phoneService = response.phoneService;
        this.emailService = response.emailService;
        this.otherService = response.otherService;

        this.loadingComponent(false);
      } catch (error) {
        this.loadingComponent(false);
        if (error.response.status === 401) {
          await this.$store.dispatch('logout');
        } else if (error.response.status === 433) {
          await this.$store.dispatch('unverify');
        } else if (error.response) {
          this.showError(error.response.data.message)
        } else if (error.request) {
          this.showError(error.request)
        } else {
          this.showError(error.message)
        }
      }
    },
    // sets the loading component to the passed value (can be true or false)
    loadingComponent(val) {
      this.loading = val;
    },
    // sets the error message component with the message passed 
    showError(msg) {
      this.$toast.error(msg, {
        timeout: 4000
      });
    }
  }
}
</script>
