<template>
  <div>
    <v-overlay
        :value="loading"
    >
      <v-progress-circular indeterminate size="100">
        {{ $t('loadingBtn') }}
      </v-progress-circular>
    </v-overlay>

    <v-tabs
        v-model="tab"
        show-arrows
        centered
        center-active
        background-color="transparent"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-if="displayInvoice" href="#invoices"> {{ $t('invoices') }}
      </v-tab>
      <v-tab href="#makepayment"> {{ $t('makePayment') }}
      </v-tab>
      <v-tab href="#paymentinformation"> {{ $t('paymentMethod') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items :value="tab" id="transparent-background">
      <v-tab-item value="invoices">
        <invoices
            @loading="val => loading = val"
            @error-message="showError"
        />
      </v-tab-item>
      <v-tab-item value="makepayment">
        <make-payment
            @loading="val => loading = val"
            @error-message="showError"
        />
      </v-tab-item>
      <v-tab-item value="paymentinformation">
        <payment-information
            @loading="val => loading = val"
            @error-message="showError"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// importing child components
import Invoices from './BillingInformation/Invoices.vue'
import MakePayment from './BillingInformation/MakePayment.vue'
import PaymentInformation from './BillingInformation/PaymentInformation.vue'
import Api from "@/api/Api";

export default {
  // child components that are being displayed
  components: {
    Invoices,
    MakePayment,
    PaymentInformation,
  },
  // initializing data
  data: () => ({
    displayInvoice: false,
    loading: false,
  }),
  async created() {
    try {
      const invoiceList = await Api.getCustomerInvoiceList(
          this.$store.getters.i_customer,
          this.$store.getters.token);

      this.displayInvoice = invoiceList.length > 0;
      if(this.displayInvoice)
        this.tab = 'invoices';
    } catch (e) {
      console.log('error', e);
      this.showError("Error fetching invoice list");
    }
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({query: {...this.$route.query, tab}})
      },
      get() {
        return this.$route.query.tab
      }
    }
  },
  methods: {
    // showing error message component with the message passed
    showError(msg) {
      this.$toast.error(msg, {
        timeout: 4000
      });
    }
  },
}
</script>
