<template>
  <v-card class="elevation-1">

    <v-card-title class="justify-center font30">{{ $t('twoFactorTitle') }}</v-card-title>

    <v-card-text>
      <v-form
          ref="form"
          lazy-validation
          @submit.prevent="verifyCustomer"
      >
        <v-card-text>
          <p>We sent a two-factor code to {{ hide_mail(email) }}. Enter code into the text box below.</p>
        </v-card-text>
        <v-text-field
            v-model="verificationCode"
            id="code"
            prepend-icon="mdi-account"
            name="code"
            :label="$t('twoFactorCode')"
            type="number"
            :rules="required"
        ></v-text-field>


        <div class="mt-5 center">
          <v-btn
              block
              @click.prevent="resendTwoFactorCode"
              color="blue-grey"
              dark
              id="resendCode"
          >
            {{ $t('resendCode') }}
          </v-btn>
        </div>


        <div class="mt-5 center">
          <v-btn
              block
              color="green"
              dark
              type="submit"
          >
            {{ $t('submitBtn') }}
          </v-btn>
        </div>

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
// importing map actions from vuex
import {mapActions} from "vuex";

export default {
  props: ['email'],
  // initializing data
  data: function () {
    return {
      verificationCode: '',
      required: [
        value => !!value || this.$i18n.t('requiredRule'),
      ],
    }
  },
  methods: {
    // using mapActions to bind actions into this component's method
    ...mapActions(["verify", "resendCode"]),
    // makes a request to the login action to validate the customer
    // credentials
    async verifyCustomer() {
      if (this.$refs.form.validate()) {
        this.$emit('loading', true);
        // appending credentials to form data variable
        let form_data = new FormData();
        form_data.append('two_factor_code', this.verificationCode);
        try {
          // calling the login method from the mapActions
          await this.verify(form_data);
          this.$emit('loading', false);
          // redirecting customer to dashboard route if
          // the customer credentials are validated
          this.$router.push("dashboard");
        } catch (error) {
          this.$emit('loading', false);
          if (error.response.status === 403) {
            this.$emit('error-message', this.$t('wrongTwoFactorMsg'));
          } else if (error.response.status === 401) {
            this.$emit('error-message', this.$t('unAuthorizedMsg'));
          } else if (error.response) {
            this.$emit('error-message', error.response.data.message);
          } else if (error.request) {
            this.$emit('error-message', error.request);
          } else {
            this.$emit('error-message', error.message);
          }
        }
      }
    },

    async resendTwoFactorCode() {
      this.$emit('loading', true);
      // appending credentials to form data variable
      try {
        // calling the resendCode method from the mapActions
        await this.resendCode();
        this.$emit('loading', false);
        this.$toast.error("New code sent to email", {
          timeout: 4000
        });
      } catch (error) {
        this.$emit('loading', false);
        if (error.response.status === 403) {
          this.$emit('error-message', this.$t('wrongTwoFactorMsg'));
        } else if (error.response.status === 401) {
          this.$emit('error-message', this.$t('unAuthorizedMsg'));
        } else if (error.response) {
          this.$emit('error-message', error.response.data.message);
        } else if (error.request) {
          this.$emit('error-message', error.request);
        } else {
          this.$emit('error-message', error.message);
        }
      }
    },
    hide_mail(email) {
      console.log(email);
      const mail_segments = email.split('@');
      mail_segments[0] = mail_segments[0].slice(0, 1) + "***" + mail_segments[0].slice(-1);

      return mail_segments.join('@');
    }
  },
};
</script>