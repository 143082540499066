<template>
  <div>
    <v-overlay
        :value="loading"
    >
      <v-progress-circular indeterminate size="100">
        {{ $t('loadingBtn') }}
      </v-progress-circular>
    </v-overlay>

    <v-container>
      <v-row>
        <v-col
            cols="12"
            :md="(isCorporativeCustomer() ? 6 : 4)"
        >
          <bill-card
              v-bind:lastInvoiceBalance="lastInvoiceBalance"
              v-bind:lastInvoiceDueDate="lastInvoiceDueDate"
              @loading="val => loading = val"
              @error-message="showError"
          />
        </v-col>

        <v-col
            cols="12"
            :md="(isCorporativeCustomer() ? 6 : 4)"
        >

          <current-balance-card
              v-bind:balance="balance"
              v-bind:payments="payments"
              v-bind:adjustments="adjustments"
              @loading="val => loading = val"
              @error-message="showError"
          />

          <v-card class="mt-1">
            <v-card-text>
              <v-row class="my-5 font25 pa-0 mx-4">
                <v-col class="pa-0 black--text">
                  {{ $t('currentBalance') }}
                </v-col>
                <v-col v-if="balance !== null" style="text-align: end" class="pa-0 black--text">
                  {{ formatToCurrency(balance) }}
                </v-col>
                <v-col v-else style="text-align: end" class="pa-0">
                  {{ "$0.00" }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

        </v-col>

        <v-col
            v-if="!isCorporativeCustomer()"
            cols="12"
            md="4"
        >
          <account-status-card
              v-bind:services="services"
              @error-message="showError"
          />
        </v-col>
      </v-row>

      <v-row
          class="mt-5"
          v-if="!isCorporativeCustomer()"
      >
        <v-col
            cols="12"
            sm="12"
        >
          <services-card
              v-bind:services="services"
              @error-message="showError"
          />
        </v-col>

      </v-row>
      <v-row justify="center">
        <v-dialog
            v-model="ssnDialog"
            persistent
            max-width="400"
        >
          <v-card>
            <v-card-title class="text-h5">
              {{ $t('ssnRequireNoticeHeader') }}
            </v-card-title>
            <v-card-text> {{ $t('ssnRequireNotice1') }}<a target="_blank"
                                                          href="https://hacienda.pr.gov/sites/default/files/documentos/480.7e_2022_informativo.pdf">480.7E</a>
              {{ $t('ssnRequireNotice2') }} <span style="font-weight: bold;">{{ this.customerFullName }}.</span>
            </v-card-text>
            <v-card-actions>
              <v-form v-model="ssnSaveForm">
                <v-text-field label="###-##-####" :rules="rules.social" v-model="socialNum" v-facade="'###-##-####'"/>
              </v-form>
              <v-spacer></v-spacer>
              <v-btn :disabled="!ssnSaveForm" @click="submitSsn">{{ $t('submitBtn') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
            v-model="xgsDialog"
            persistent
            max-width="400"
        >
          <v-card>
            <v-card-title class="text-h5">
              {{ $t('xgsConfirmationNoticeHeader') }}
            </v-card-title>
            <v-card-text> {{ $t('xgsRequireNotice1') }}
            </v-card-text>
            <v-card-text>
              {{ $t('xgsProductLabel') + productNameLabel}}
              <br/>
              <br/>
              <p>{{ $t('xgsDescription')}} <a href="https://opticofiber.com/help/subscriber-agreement/" target="_blank">https://opticofiber.com/help/subscriber-agreement/</a></p>
            </v-card-text>

            <v-card-actions v-if="!xgsRejected">
              <v-btn @click="submitAcceptXgs">{{ $t('acceptBtn') }}</v-btn>
              <v-btn color="red" @click="toRejectConfirmation">{{ $t('rejectBtn') }}</v-btn>
            </v-card-actions>

            <v-card-actions v-if="xgsRejected">
              <v-textarea
                  v-model="reasonText"
                  label="Reason"
              ></v-textarea>
              <v-spacer></v-spacer>
              <v-btn @click="submitXgsRejection">{{ $t('submitBtn') }}</v-btn>
              <v-btn @click="rejectXgsBack">{{ $t('backBtn') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// importing child components
import BillCard from '@/components/Dashboard/BillCard.vue'
import ServicesCard from '@/components/Dashboard/ServicesCard.vue'
import AccountStatusCard from '@/components/Dashboard/AccountStatusCard.vue'
import CurrentBalanceCard from '@/components/Dashboard/CurrentBalanceCard.vue'
// importing Api requests
import Api from '@/api/Api';

export default {
  name: 'dashboard-information',
  // child components that are being displayed
  components: {
    BillCard,
    ServicesCard,
    AccountStatusCard,
    CurrentBalanceCard
  },
  // initializing the data
  data: () => ({
    loading: false,
    customerFullName: "",
    ssnSaveForm: false,
    xgsRejected: false,
    productNameLabel:"",
    reasonText: "",
    socialNum: "",
    services: {},
    balance: 0.00,
    lastInvoiceBalance: null,
    lastInvoiceDueDate: null,
    payments: null,
    adjustments: null,
    ssnRespond: false,
    xgsRespond: false,
    ssnDialog: false,
    xgsDialog: false,
    rules: {
      social: [
        v => !!v || "Required",
        v =>
            new RegExp(
                "^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$",
                "ig"
            ).test(v) || "Not a valid SSN",
      ]
    }
  }),
  async created() {
    const iCustomer = this.$store.getters.i_customer;
    const response = await Api.checkSsn(this.$store.getters.token, iCustomer);
    const xgsResponse = await Api.checkUpgradeXgs(this.$store.getters.token, iCustomer);
    if (response.data.ssn === null) {
      this.customerFullName = response.data.name;
      //   display ssn require dialog
      this.ssnRespond = true;
    }

    if (xgsResponse.data.length > 0) {
      this.productNameLabel = xgsResponse.data;
      this.xgsRespond = true;
    }
    // check which dialog to open
    if (this.ssnRespond && this.xgsRespond) {
      this.ssnDialog = true;
    } else {
      if (this.ssnRespond && !this.xgsRespond) {
        this.ssnDialog = true;
      } else {
        if (!this.ssnRespond && this.xgsRespond) {
          this.xgsDialog = true;
        }
      }
    }
  },
  // getting the data once the component is mounted
  async mounted() {
    await this.getData();
  },
  methods: {
    async submitAcceptXgs() {
      try {
        this.loading = true;
        const data = {
          customer_id: this.$store.getters.i_customer,
          customer_name: this.$store.getters.StateUser.name,
          confirmation: true,
          reason: null
        }
        const response = await Api.confirmationUpgradeXgs(this.$store.getters.token, data);
        this.loading = false;
        this.$toast.success(this.$i18n.t('xgsTicketAcceptance') + response.data, {
          timeout: 4000
        });
        this.xgsDialog = false;
      } catch (e) {
        this.showError("Error occurred.Could not reject XGS Request.")
      }
    },
    async submitXgsRejection() {
      try {
        this.loading = true;
        const data = {
          customer_id: this.$store.getters.i_customer,
          customer_name: this.$store.getters.StateUser.name,
          confirmation: false,
          reason: this.reasonText
        }
        await Api.confirmationUpgradeXgs(this.$store.getters.token, data);
        this.loading = false;
        this.xgsDialog = false;
        this.$toast.success(this.$i18n.t('xgsTicketCanceled'), {
          timeout: 4000
        });
      } catch (e) {
        this.showError("Error occurred.Could not reject XGS Request.")
      }
    },
    rejectXgsBack() {
      this.reasonText = "";
      this.xgsRejected = false;
    },
    toRejectConfirmation() {
      this.xgsRejected = true;
    },
    // checking if current customer is corporative
    isCorporativeCustomer() {
      return this.$store.getters.isCorporativeCustomer;
    },
    // fetches the customer acocunt list and the customer last invoice
    // to pss it to the child components
    async getData() {
      try {
        this.loading = true;
        this.services = await this.getCustomerAccountList();
        const balanceData = await this.getCustomerCurrentBalance();
        this.lastInvoiceBalance = balanceData.invoiceBalance;

        this.balance = balanceData.balance;
        this.payments = balanceData.payments;
        this.adjustments = balanceData.adjustments;
        if (balanceData.invoiceDueDate) {
          this.lastInvoiceDueDate = new Date(balanceData.invoiceDueDate).getTime();
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        if (error.response.status === 401) {
          this.$store.dispatch('logout');
        } else if (error.response.status === 433) {
          this.$store.dispatch('unverify');
        } else if (error.response) {
          this.showError(error.response.data.message)
        } else if (error.request) {
          this.showError(error.request)
        } else {
          this.showError(error.message)
        }
      }
    },
    async submitSsn() {
      try {
        this.loading = true;
        await Api.saveSsn(this.$store.getters.token, {
          i_customer: this.$store.getters.i_customer,
          social_num: this.socialNum
        });
        this.socialNum = "";
        this.ssnDialog = false;
        this.loading = false;
        if (this.xgsRespond) {
          this.xgsDialog = true;
        }
      } catch (error) {
        console.log('error', error);
        this.showError("Error occurred.Could not save Social Security number.")
      }
    },
    formatToCurrency(variable) {
      return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }
      ).format(variable);
    },
    // gets the customer account list and returns the response data
    async getCustomerAccountList() {
      return await Api.getCustomerAccountList(
          this.$store.getters.i_customer,
          this.$store.getters.token);
    },
    // gets the customer last invoice and returns the response data
    async getCustomerLastInvoice() {
      let response = await Api.getCustomerLastInvoice(
          this.$store.getters.i_customer,
          this.$store.getters.token);
      return response;
    },
    // gets the customer current balance and returns the response data
    async getCustomerCurrentBalance() {
      let response = await Api.getCustomerCurrentBalance(
          this.$store.getters.i_customer,
          this.$store.getters.token);
      return response;
    },
    // shows an error message component with the message passed
    showError(msg) {
      this.$toast.error(msg, {
        timeout: 4000
      });
    }
  }
  ,
}
</script>
