<template>
    <v-card elevation="1">
        <v-card-title class="font25">{{$t('voiceCalls')}}</v-card-title>
        <v-row align="center" class="mx-3">
            <v-col
            class="d-flex"
            cols="12"
            sm="4"
            >
                <v-text-field
                v-model="from"
                :label="$t('from')"
                type="date"
                @change="onDateChange"
                hide-details
                ></v-text-field>
            </v-col>
            <v-col
            class="d-flex"
            cols="12"
            sm="4"
            >
                <v-text-field
                v-model="until"
                :label="$t('until')"
                type="date"
                @change="onDateChange"
                hide-details
                ></v-text-field>
            </v-col>
            <v-col
            class="d-flex"
            cols="12"
            sm="4"
            >
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('search')"
                @change="onDateChange"
                single-line
                hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
        :headers="voiceCallsDatatableHeaders"
        :items="voiceCalls"
        :search="search"
        :items-per-page="10"
        >
            <template v-slot:[`item.connect_time`]="{item}">
                {{moment(item.connect_time)}}
            </template>
            <template v-slot:[`item.charged_quantity`]="{item}">
                {{secondsToHms(item.charged_quantity)}}
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
// importing Api requests
import Api from '@/api/Api';
// importing moment formatter
import moment from 'moment'

  export default {
    // receiving customer phone services from parent component
    props: ['phoneService'],
    data: () => ({
        voiceCalls: [],
        from: '',
        until: '',
        search: '',
    }),
    computed: {
        // setting the voice call datatable headers
        voiceCallsDatatableHeaders() {
            return [
                { text: this.$t('from'), value: 'CLI' },
                { text: this.$t('to'), value: 'CLD' },
                { text: this.$t('country'), value: 'country' },
                { text: this.$t('connectTime'), value: 'connect_time' },
                { text: this.$t('duration'), value: 'charged_quantity' },
            ]
        }
    },
    // calling the setVoiceCallDates and getVoiceCalls methods 
    // once the component is mounted
    mounted() {
        this.setVoiceCallDates();
        this.getVoiceCalls();
    },
    methods: {
        // fetching the voice calls 
        async getVoiceCalls() {
            try {
                this.$emit('loading', true);
                let accounts = [];
                // appending all i_accounts from all phone services into 
                // an array
                this.phoneService.forEach(element => {
                    accounts.push(element['i_account']);
                });
                // making the request
                let response = await Api.getCustomerVoiceCalls(
                this.$store.getters.i_customer,
                this.$store.getters.token,
                this.from,
                this.until,
                accounts,
                );

                // let response1 = await Api.getXdrInfo(
                //     101313367,
                //     this.$store.getters.token
                // );

                // assigning the response data to the voice call
                // variable
                this.voiceCalls = response.data;
                this.$emit('loading', false);
            } catch (error) {
                this.$emit('loading', false);
                if (error.response.status === 401) {
                  await this.$store.dispatch('logout');
                } else if (error.response.status === 433) {
                  await this.$store.dispatch('unverify');
                } else if (error.response) {
                    this.$emit('error-message', error.response.data.message);
                } else if (error.request) {
                    this.$emit('error-message', error.request);
                } else {
                    this.$emit('error-message', error.message);
                }
            }
        },
        // setting the intial voice call from and until date 
        setVoiceCallDates() {
            const today = new Date();
            const last_month = new Date();
            this.from = (last_month.getFullYear()+'-'+(last_month.getMonth()).toString().padStart(2, "0")+'-'+last_month.getDate());
            this.until = (today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, "0")+'-'+today.getDate());
        },
        // when the from and/or until date changes, the voice call data is going
        // to be fetched again
        onDateChange() {
            this.getVoiceCalls();
        },
        // method to convert seconds to hours, minutes and seconds (it varies)
        secondsToHms(seconds) {
            let d = Number(seconds);
            let h = Math.floor(d / 3600);
            let m = Math.floor(d % 3600 / 60);
            let s = Math.floor(d % 3600 % 60);

            let hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
            let mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
            let sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
            return hDisplay + mDisplay + sDisplay; 
        },
        // method to format a simple date (2000-01-01) to Januaryt 1st, 2000 12:00: PM
        moment(item) {
            return moment(item).subtract(4, 'h').format('MMMM Do YYYY, h:mm a');
        },
    }
  }
</script>