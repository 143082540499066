<template>
  <v-container class="width50">
    <v-row>
      <v-col cols="12" sm="6"> 
        <div class="font25">
          {{$t('invoiceDetails')}}
        </div>
        <div>
          <a @click="downloadInvoice()">{{$t('downloadInvoice')}}</a>
        </div>
      </v-col>

      <v-col cols="12" sm="6"> 
        <div>
          {{$t('invoiceDate')}}
        </div>
        <v-select
          v-model="selectedInvoice"
          :items="invoiceData.slice().reverse()"
          :item-text="item => $d(item.issue_date, 'long')"
          item-value="i_invoice"
          name="i_invoice"
          solo
          @change="onChangeInvoiceSelect"
        ></v-select>
      </v-col>
    </v-row>

    <div>
      <v-row>
        <v-col cols="12" sm="12">
          <div class="font25">
            {{$t('subscriptionCharges')}}
          </div>
        </v-col>
      </v-row>

      <v-row class="font12">
        <v-col cols="12" xs="12"> 
          <v-list id="transparent-background">
            <v-list-item
            v-for="(item, name, index) in subscriptions"
            :key="index"
            >
              <v-row class="mb-1">
                <v-col cols="6" xs="12" md="6">
                  {{item.CLD}} 
                  <div v-if="item.account_id">({{item.account_id}})</div>
                </v-col>
                <v-col cols="6" xs="12" md="6" class="right">
                  <strong>{{ formatToCurrency(item.charged_amount) }}</strong>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>

          <v-row class="mx-1">
            <v-col cols="6" xs="12" md="6">
              <em><strong>{{$t('totalSubscriptions')}}</strong></em>
            </v-col>
            <v-col cols="6" xs="12" md="6" class="right">
              <strong>{{ formatToCurrency(selectedInvoice.subscriptions) }}</strong>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

      <div class="mt-8">
        <v-row>
          <v-col cols="12" sm="12">
            <div class="font25">
              {{$t('otherCharges')}}
            </div>
          </v-col>
        </v-row>

        <v-row class="font12">
          <v-col cols="12" xs="12"> 
            <v-list id="transparent-background">
              <v-list-item>
                <v-row class="mb-1">
                  <v-col cols="6" xs="12" md="6">
                    {{$t('calls')}}
                  </v-col>
                  <v-col cols="6" xs="12" md="6" class="right">
                    <strong>{{ formatToCurrency(selectedInvoice.calls) }}</strong>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-row class="mb-1">
                  <v-col cols="6" xs="12" md="6">
                    {{$t('taxes')}}
                  </v-col>
                  <v-col cols="6" xs="12" md="6" class="right">
                    <strong>{{ formatToCurrency(selectedInvoice.taxes) }}</strong>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-row class="mb-1">
                  <v-col cols="6" xs="12" md="6">
                    {{$t('manualCharges')}}
                  </v-col>
                  <v-col cols="6" xs="12" md="6" class="right">
                    <strong>{{ formatToCurrency(selectedInvoice.manual_charges) }}</strong>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-row class="mb-1">
                  <v-col cols="6" xs="12" md="6">
                    <em><strong>{{$t('totalOtherCharges')}}</strong></em>
                  </v-col>
                  <v-col cols="6" xs="12" md="6" class="right">
                    <strong>{{ formatToCurrency(selectedInvoice.manual_charges + selectedInvoice.taxes + selectedInvoice.calls) }}</strong>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </div>

      <v-divider></v-divider>

      <v-row class="my-5">
        <v-col cols="6" xs="12" md="6">
          <div class="font25">
            {{$t('totalDue')}} {{$d(selectedInvoice.issue_date, 'long')}}
          </div>
        </v-col>
        <v-col class="font25 right" cols="6" xs="12" md="6">
          <strong>{{ formatToCurrency(selectedInvoice.amount_net) }}</strong>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
// importing Api requests
import Api from '@/api/Api';

export default {
  // initializing data
  data: () => ({
    invoiceData: [],
    subscriptions: [],
    selectedInvoice: {
      amount_net: 0.00,
      subscriptions: 0.00,
      taxes: 0.00,
      calls: 0.00,
      manual_charges: 0.00
    },
  }), 
  // getting the invoices once the component is mounted
  mounted() {
    this.getInvoices();
  },
  methods: {
    // an event once an invoice date is changed in the select component
    onChangeInvoiceSelect(i_invoice) {
      // searching for the i_invoice in the invoiceData array so when it's
      // found, it will be returned
      let element = this.invoiceData.filter(function(e){
        if(e.i_invoice === i_invoice) return e;
      });
      // updating the currentInvoice with the one found
      this.selectedInvoice = element[0];    
      // getting the subscriptions of the selectedInvoice
      this.getSubscriptions(this.selectedInvoice.period_from, this.selectedInvoice.period_to);
    },
    async getInvoices() {
      try {
        this.$emit('loading', true);
        // fetching the invoice list so it can be displayed to the customer
        let response = await Api.getCustomerInvoiceList(
          this.$store.getters.i_customer,
          this.$store.getters.token);
        
        // looping through the response and updating the invoiceData array with
        // the response data
        response.forEach(value => {
          this.invoiceData.push({
            issue_date: (new Date(value.issue_date).getTime() + 86400000),
            i_invoice: value.i_invoice,
            period_from: value.period_from,
            period_to: value.period_to,
            amount_due: value.amount_due,
            amount_net: value.amount_net,
            calls: value.calls,
            adjustments: value.adjustments,
            manual_charges: value.manual_charges,
            subscriptions: value.subscriptions,
            taxes: value.taxes,
          });
        })
        this.$emit('loading', false);
        // making the selectedInvoice default value (the last invoice is set as default)
        this.selectedInvoice = this.invoiceData[this.invoiceData.length-1];

        // fetching the last invoice subscriptions
        this.getSubscriptions(this.selectedInvoice.period_from, this.selectedInvoice.period_to);
      } catch(error) {
        this.$emit('loading', false);
        if (error.response.status === 401) {
          await this.$store.dispatch('logout');
        } else if (error.response.status === 433) {
          await this.$store.dispatch('unverify');
        } else if (error.response) {
          this.$emit('error-message', error.response.data.message)
        } else if (error.request) {
          this.$emit('error-message', error.request)
        } else {
          this.$emit('error-message', error.message)
        }
      }
    },
    // fetches all subscriptions based on from period and to period
    async getSubscriptions(period_from, period_to){
      try {
        this.$emit('loading', true);
        // fetching the subscriptions
        let response = await Api.getCustomerAccountXdrs(
          this.$store.getters.i_customer,
          this.$store.getters.token,
          period_from,
          period_to,
          4
        )
        // setting the subscription list with the response data
        this.subscriptions = response;

        this.$emit('loading', false);
      } catch(error) {
        this.$emit('loading', false);
        if (error.response.status === 401) {
          await this.$store.dispatch('logout');
        } else if (error.response.status === 433) {
          await this.$store.dispatch('unverify');
        } else if (error.response) {
          this.$emit('error-message', error.response.data.message)
        } else if (error.request) {
          this.$emit('error-message', error.request)
        } else {
          this.$emit('error-message', error.message)
        }
      }
    },
    // download invoice event
    async downloadInvoice() {
      try {
        // fetching the selected invoice pdf information
        let response = await Api.getCustomerInvoicePdf(
          this.$store.getters.i_customer,
          this.selectedInvoice.i_invoice, 
          this.$store.getters.token);

        // creating a URL and an a element to download the pdf with the response data
        let fileURL = window.URL.createObjectURL(new Blob([response]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `Invoice_${this.selectedInvoice.i_invoice}.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch(error) {
        if (error.response.status === 401) {
          await this.$store.dispatch('logout');
        } else if (error.response.status === 433) {
          await this.$store.dispatch('unverify');
        } else if (error.response) {
          this.$emit('error-message', error.response.data.message)
        } else if (error.request) {
          this.$emit('error-message', error.request)
        } else {
          this.$emit('error-message', error.message)
        }
      }
    },
    // formatting the currency input data
    formatToCurrency(variable) {
      return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'}
      ).format(variable);
    },
  }
}
</script>
