// importing all libraries that are needed
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from '@/plugins/i18n';
import router from './router';
import FlagIcon from 'vue-flag-icon';
import axios from 'axios';
import store from './store/modules';
import InputFacade from 'vue-input-facade';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSession from "vue-session";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import './assets/css/style.css';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.use(InputFacade);
Vue.use(VueSession);
Vue.use(FlagIcon);
Vue.use(VueSweetalert2);
Vue.use(Toast);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
