<template>
  <v-row>
    <v-col cols="12">
      <div v-if="!small">
        <v-stepper
            v-model="e1"
            vertical
            elevation="1"
        >
          <v-stepper-step
              step="1"
              :complete="e1 > 1"
          >
            <div class="font20">
              {{ $t('paymentMethod') }}
            </div>
          </v-stepper-step>

          <v-stepper-content step="1">
            <stepper-one
                v-bind:saved_payment_method="saved_payment_method"
                v-bind:showSavedPaymentMethodBtn="showSavedPaymentMethodBtn"
                @next-step="nextStep"
                @error-message="showError"
                @payment_method="(val) => payment_method = val"
                @payment_type="(val) => payment_type = val"
                @transaction_id="(val) => transaction_id = val"
                @loading="loading"
            />
          </v-stepper-content>

          <v-stepper-step
              :complete="e1 > 2"
              step="2"
          >
            <div class="font20">
              {{ $t('amountToCharge') }}
            </div>
          </v-stepper-step>

          <v-stepper-content step="2">
            <stepper-two
                v-bind:payment_method="payment_method"
                v-bind:payment_type="payment_type"
                v-bind:saved_payment_method="saved_payment_method"
                v-bind:balance="balance"
                @error-message="showError"
                @loading="loading"
            />
          </v-stepper-content>
        </v-stepper>
      </div>

      <div v-else>
        <div>
          <v-stepper
              v-model="e1"
              elevation="1">
            <v-stepper-header>
              <v-stepper-step step="1"></v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2"></v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                  step="1"
              >
                <stepper-one
                    v-bind:saved_payment_method="saved_payment_method"
                    v-bind:showSavedPaymentMethodBtn="showSavedPaymentMethodBtn"
                    @next-step="nextStep"
                    @error-message="showError"
                    @payment_method="(val) => payment_method = val"
                    @payment_type="(val) => payment_type = val"
                    @transaction_id="(val) => transaction_id = val"
                    @loading="loading"
                />
              </v-stepper-content>
              <v-stepper-content
                  step="2"
              >
                <stepper-two
                    v-bind:payment_method="payment_method"
                    v-bind:payment_type="payment_type"
                    v-bind:saved_payment_method="saved_payment_method"
                    v-bind:balance="balance"
                    @error-message="showError"
                    @loading="loading"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// importing child components
import StepperOne from './StepperOne.vue';
import StepperTwo from './StepperTwo.vue';
// importing Api requests
import Api from '@/api/Api';

export default {
  name: "payment-stepper",
  // child components
  components: {
    StepperOne,
    StepperTwo,
  },
  // initializing data
  data: () => ({
    e1: 1,
    steps: 2,
    payment_method: [],
    payment_type: '',
    saved_payment_method: null,
    balance: 0,
    small: false,
    showSavedPaymentMethodBtn: false,
  }),
  watch: {
    // watching the steps
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  // fetches the customer's payment information and the balance once the component
  // is mounted
  mounted() {
    this.small = window.innerWidth < 960;

    this.loading(true);
    this.getPaymentInformation();
    this.getCustomerBalance();
  },
  methods: {
    // fetch the customer's payment information
    async getPaymentInformation() {
      try {
        // fetching the data of the customer's payment information
        let response = await Api.getCustomerPaymentInformation(this.$store.getters.i_customer, this.$store.getters.token);
        // setting the saved payment method to the response saved payment method that is
        // collected from porta 
        this.saved_payment_method = response.number ?? response.account_number;
        this.showSavedPaymentMethodBtn = true;
        this.loading(false);
      } catch (error) {
        this.showSavedPaymentMethodBtn = false;
        this.loading(false);
        if (error.response.status === 401) {
          await this.$store.dispatch('logout');
        } else if (error.response.status === 433) {
          await this.$store.dispatch('unverify');
        } else if (error.response) {
          this.showError(error.response.data.message);
        } else if (error.request) {
          this.showError(error.request);
        } else {
          this.showError(error.message);
        }
      }
    },
    // fetches the customer's current balance
    async getCustomerBalance() {
      try {
        this.loading(true);
        const balanceData = await this.getCustomerCurrentBalance();
        // setting the responses to the corresponding variables
        this.balance = balanceData.balance;
      } catch (error) {
        this.loading(false);
        if (error.response.status === 401) {
          this.$store.dispatch('logout');
        } else if (error.response.status === 433) {
          await this.$store.dispatch('unverify');
        } else if (error.response) {
          this.showError(error.response.data.message);
        } else if (error.request) {
          this.showError(error.request);
        } else {
          this.showError(error.message);
        }
      }
    },

    // gets the customer current balance and returns the response data
    async getCustomerCurrentBalance() {
      return await Api.getCustomerCurrentBalance(
          this.$store.getters.i_customer,
          this.$store.getters.token);
    },
    // setting the next step for the stepper component
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    // emitting an error message to parent component
    showError(msg) {
      this.$emit('error-message', msg);
    },
    // emitting loading component to parent component
    loading(val) {
      this.$emit('loading', val);
    },
  }
}
</script>
