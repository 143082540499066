<template>
  <v-card class="elevation-1">

    <v-card-title class="justify-center font30">{{ $t('loginBtn') }}</v-card-title>

    <v-card-text>
      <v-form
          ref="form"
          lazy-validation
          @submit.prevent="validateCustomer"
      >
        <v-text-field
            v-model="customer.login"
            id="login"
            prepend-icon="mdi-account"
            name="login"
            :label="$t('login')"
            type="login"
            :rules="required"
        ></v-text-field>
        <v-text-field
            v-model="customer.password"
            id="current_password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            name="current_password"
            :label="$t('password')"
            :type="showPassword ? 'text' : 'password'"
            :rules="required"
            @click:append="showPassword = !showPassword"
        ></v-text-field>

        <router-link class="mx-8" to="/forgotPassword">{{ $t('forgotPassword') }}</router-link>

        <div class="mt-5 center">
          <v-btn
              color="blue-grey"
              dark
              type="submit"
          >
            {{ $t('loginBtn') }}
          </v-btn>
        </div>

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
// importing map actions from vuex
import {mapActions} from "vuex";

export default {
  // initializing data
  data: function () {
    return {
      customer: {
        login: '',
        password: ''
      },
      showPassword: false,
      required: [
        value => !!value || this.$i18n.t('requiredRule'),
      ],
    }
  },
  methods: {
    // using mapActions to bind actions into this component's method
    ...mapActions(["login"]),
    // makes a request to the login action to validate the customer
    // credentials
    async validateCustomer() {
      if (this.$refs.form.validate()) {
        this.$emit('loading', true);
        // appending credentials to form data variable
        let form_data = new FormData();
        form_data.append('login', this.customer.login);
        form_data.append('password', this.customer.password);
        try {
          // calling the login method from the mapActions
          await this.login(form_data);
          this.$emit('loading', false);
          // redirecting customer to dashboard route if
          // the customer credentials are validated
          this.$router.push({
            name: "Verify",
            params:
                {
                  email: this.customer.login
                }
          });
        } catch (error) {
          this.$emit('loading', false);
          if (error.response.status === 403) {
            this.$emit('error-message', this.$t('wrongCredentialsMsg'));
          } else if (error.response.status === 401) {
            this.$emit('error-message', this.$t('unAuthorizedMsg'));
          } else if (error.response) {
            this.$emit('error-message', error.response.data.message);
          } else if (error.request) {
            this.$emit('error-message', error.request);
          } else {
            this.$emit('error-message', error.message);
          }
        }
      }
    }
  }
};
</script>