<template>
  <div>
    <v-overlay
    :value="loading"
    >
      <v-progress-circular indeterminate size="100">
      {{$t('loadingBtn')}}
      </v-progress-circular>
    </v-overlay>
    <v-tabs 
    v-model="tab"
    show-arrows
    centered
    center-active
    background-color="transparent"
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#personalInformation"> {{ $t('personalInfo') }}</v-tab>
      <v-tab href="#changePassword"> {{ $t('changePassword') }}</v-tab>

      </v-tabs>
      
      <v-tabs-items :value="tab" id="transparent-background">

        <v-tab-item value="personalInformation">
          <customer-information-card 
          v-bind:customer_information="customer_information" 
          />
        </v-tab-item>

        <v-tab-item value="changePassword">
          <change-password-card
          @loading="val => loading = val"
          @error-message="showError"
          />
        </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
// importing child components
import ChangePasswordCard from '@/components/Profile/ChangePasswordCard.vue';
import CustomerInformationCard from '@/components/Profile/CustomerInformationCard.vue';

import Api from '@/api/Api';

export default {
  name: 'my-profile',
  // child components that are being displayed
  components: {
    CustomerInformationCard,
    ChangePasswordCard,
  },
  // initializing the data 
  data: () => ({
    customer_information: {
      name: '',
      balance: null,
      iso_4217: '',
      companyname: '',
      firstname: '',
      midinit: '',
      lastname: '',
      baddr1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      note: '',
      cont1: '',
      phone1: '',
      faxnum: '',
      phone2: '',
      cont2: '',
      email: '',
    },
    loading: false,
  }),
  // calling the customer information once the component is mounted
  mounted() {
    this.loading = true;
    this.getCustomerInformation();
  },
  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    }
  },
  methods: {
    // fetches the customer information
    async getCustomerInformation() {
      try {
        // makes request to get the customer information
        let response = await Api.getCustomerContactInformation(this.$store.getters.i_customer, this.$store.getters.token);
        // sets de customer information with the response data
        this.customer_information = response;
        this.loading = false;
      } catch(error) {
        if (error.response.status === 401) {
          await this.$store.dispatch('logout');
        } else if (error.response.status === 433) {
          await this.$store.dispatch('unverify');
        } else if (error.response) {
          this.showError(error.response.data.message);
        } else if (error.request) {
          this.showError(error.request);
        } else {
          this.showError(error.message);
        }
      }
    },
    // showing the error message component with the message passed
    showError(msg) {
      this.$toast.error(msg, {
        timeout: 4000
      });
    }
  }
}
</script>
