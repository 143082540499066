<template>
    <v-row align="center" justify="center">
        <v-col cols="12">
            <v-card elevation="1">
                <v-card-title class="font20">{{$t('paymentMethod')}}</v-card-title>
                <v-card-text>
                    <v-container elevation="0">
                        <v-radio-group v-model="paymentMethodSelectedItem">
                            <v-radio
                            v-if="showSavedPaymentMethod"
                            value="savedPaymentMethod"
                            name="savedPaymentMethod"
                            @click="onChangePaymentMethod()"
                            >
                                <template v-slot:label>
                                    <div>{{$t('savedPaymentMethod')}}</div>
                                </template>
                            </v-radio>
                            <v-radio
                            value="newPaymentMethod"
                            name="newPaymentMethod"
                            @click="onChangePaymentMethod()"
                            >
                                <template v-slot:label>
                                    <div>{{$t('newPaymentMethod')}}</div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-container>
               
                    <div v-if="showNewPaymentMethodForm">
                        <v-container elevation="0">
                            <v-card-text>
                                <div v-if="showCardPaymentForm">
                                    <v-form
                                    ref="form"
                                    lazy-validation
                                    @submit.prevent="submitPaymentInformation"
                                    >   
                                        <div class="font16"><strong>{{$t('changeCard')}}</strong></div>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-text-field
                                                v-model="new_card_info.cardholder_name"
                                                :rules="required"
                                                :error-messages="errorMessages.cardholder_name"
                                                :label="$t('cardholderName')"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                v-model="new_card_info.credit_card_number_formatted"
                                                :label="$t('cardNumber')"
                                                :rules="cardNoRules"
                                                :maxlength="cardNumberMaxLength"
                                                :error-messages="errorMessages.credit_card_number"
                                                :value="ccFormat(new_card_info.credit_card_number_formatted)"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                v-model="new_card_info.cvv"
                                                :label="$t('cvv')"
                                                type="password"
                                                :error-messages="errorMessages.cvv"
                                                :rules="cvvRules"
                                                ></v-text-field>
                                            </v-col>
                                             <v-col cols="12" sm="3">
                                                <v-text-field
                                                v-model="new_card_info.exp_date"
                                                maxlength="7"
                                                :label="$t('expDate')"
                                                :rules="expDateRules"
                                                :value="expDateFormat(new_card_info.exp_date)"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <div class="font16 mt-8"><strong>{{ $t("billingAddress") }}</strong></div>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                v-model="new_card_info.address1"
                                                :label="$t('address1')"
                                                :error-messages="errorMessages.address1"
                                                :rules="required"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                v-model="new_card_info.address2"
                                                :label="$t('address2')"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                v-model="new_card_info.city"
                                                :label="$t('city')"
                                                :error-messages="errorMessages.city"
                                                :rules="required"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-select
                                                v-model="new_card_info.iso_3166_1_a2"
                                                :label="$t('state')"
                                                :items="states" 
                                                value="value" 
                                                item-text="state"
                                                :error-messages="errorMessages.iso_3166_1_a2"
                                                :rules="required"
                                                >
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                v-model="new_card_info.zip"
                                                :label="$t('zip')"
                                                :error-messages="errorMessages.zip"
                                                :rules="required"
                                                type="number"
                                                maxLength="5"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                v-model="new_card_info.phone_number"
                                                :label="$t('billingPhoneNumber')"
                                                :error-messages="errorMessages.phone_number"
                                                :rules="required"
                                                type="number"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                            
                                        <v-btn
                                        color="blue-grey" 
                                        dark 
                                        block 
                                        class="ma-1"
                                        type="submit"
                                        >
                                        {{$t('saveBtn')}}
                                        </v-btn>
                                    </v-form>
                                </div>
                            </v-card-text>
                        </v-container>
                    </div>

                    <div v-if="paymentMethodSelectedItem === 'savedPaymentMethod'">
                        <saved-payment-method-card
                        v-bind:card_info="saved_payment_method_information"
                        @error-message="showError"
                        />
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
// importing child components
import SavedPaymentMethodCard from './SavedPaymentMethodCard.vue'
// importing Api requests
import Api from '@/api/Api';
// importing states
import states from '@/plugins/states';

export default ({
    // child components
    components: {
        SavedPaymentMethodCard
    },
    // initializing data
    data: function() {
        return {
            new_card_info: {
                cardholder_name: '',
                credit_card_number: '',
                credit_card_number_formatted: '',
                cvv: '',
                exp_date: '',
                address1: '',
                address2: '',
                iso_3166_1_a2: 'PR',
                city: '',
                zip: '',
                phone_number: '',
            },
            showSavedPaymentMethod: false,
            cardNumberMaxLength: 19,
            saved_payment_method_information: {},
            showNewPaymentMethodForm: false,
            showCardPaymentForm: true,
            paymentMethodSelectedItem: 'savedPaymentMethod',
            paymentTypesSelectedItem: 'card',
            states: states,
            paymentTypes: [
                { key: 1, text: 'card', value: 'card'},
                { key: 2, text: 'paypal', value: 'paypal'},
            ],
            cardNoRules: [
               value => !!value && (value.length === this.cardNumberMaxLength) || this.$i18n.t('ccNumberRule') + this.cardNumberMaxLength + this.$i18n.t('ccNumberRule2'),
            ],
            cvvRules: [
                value => !!value && (value.length >= 3 && value.length <= 4) || this.$i18n.t('ccvRule'),
            ],
            zipRules: [
                value => !!value && value.length === 5 || this.$i18n.t('zipRule'),
            ],
            expDateRules: [
                value => /^[0-9]{4}-[0-9]{2}$/.test(value) || this.$i18n.t('expDateFormatRule'),
                value => value >= new Date().toISOString().substr(0, 7) || this.$i18n.t('expDateRule'),
            ],
            required: [
                value => !!value || this.$i18n.t('requiredRule'),
            ],
            errorMessages: {
                cardholder_name: [],
                credit_card_number: [],
                cvv: [],
                exp_date: [],
                address1: [],
                iso_3166_1_a2: [],
                city: [],
                zip: [],
                phone_number: [],
            }
        }
    },
    // gets the payment information once the component has mounted
    mounted() {
        this.getPaymentInformation();
    },
    methods: {
        // Formats the exp date into YYYY-MM
        expDateFormat(date) {
            if(!date) return;
            let value = date.replace(/\D/g, '')
            if((/^\d{0,7}$/).test(value)) this.new_card_info.exp_date = value.replace(/(\d{4})/, '$1-');
        },
        // formatting the credit card depending on the typed card number
        ccFormat(number) {
            // if there's nothing, return
            if(!number) return;
            this.new_card_info.credit_card_number = this.new_card_info.credit_card_number_formatted.split(" ").join('');
            this.new_card_info.credit_card_number_formatted = number;
            let value = this.new_card_info.credit_card_number_formatted.replace(/\D/g, '')
            if ((/^3[47]\d{0,13}$/).test(value)) { // this is formatting an american express, 15 digits
                this.new_card_info.credit_card_number_formatted = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 17
            } else if ((/^\d{0,16}$/).test(value)) { // this is formatting a regular cc number, 16 digits
                this.new_card_info.credit_card_number_formatted = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
                this.cardNumberMaxLength = 19
            }
        },
        // method that shows payment method when a new payment method button is pressed
        onChangePaymentMethod() {
            this.showNewPaymentMethodForm = this.paymentMethodSelectedItem === 'newPaymentMethod';
        },
        // showing error message component with recieved message
        showError(msg) {
            this.$emit('error-message', msg);
        },
        // fetching the payment information
        async getPaymentInformation() {
            try {
                this.$emit('loading', true);
                // calling the api method to get the payment information data
                let response = await Api.getCustomerPaymentInformation(this.$store.getters.i_customer, this.$store.getters.token);
                // assigning the response data to the saved payment method
                this.saved_payment_method_information = response;
                this.showSavedPaymentMethod = true;
                this.$emit('loading', false);
            } catch(error) {
                this.showSavedPaymentMethod = false;
                this.paymentMethodSelectedItem = 'newPaymentMethod';
                this.showNewPaymentMethodForm = true;
                this.$emit('loading', false);
                if (error.response.status === 401) {
                  await this.$store.dispatch('logout');
                } else if (error.response.status === 433) {
                  await this.$store.dispatch('unverify');
                } else if (error.response) {
                    this.showError(error.response.data.message);
                } else if (error.request) {
                    this.showError(error.request);
                } else {
                    this.showError(error.message);
                }
            }
        },
        // method on charge of making the request to update the payment method
        async submitPaymentInformation() {
            if(this.$refs.form.validate()) {
                for (let field in this.errorMessages) this.errorMessages[field] = [];
                try {
                    this.$emit('loading', true);
                    // appending payment method to the form data
                    let form_data = new FormData();
                    
                    for (let key in this.new_card_info) form_data.append(key, this.new_card_info[key]);
                    
                    // making the request to change the payment method
                    await Api.changeCustomerPaymentMethod(
                        this.$store.getters.i_customer,
                        this.$store.getters.token,
                        form_data,
                    );
                    this.$emit('loading', false);

                    // showing success message component once the payment method is
                    // updated
                    this.$toast.success(this.$t('savePaymentMethodSuccessMsg'), {
                        timeout: 4000
                    });
                    // re-redering the component so the new values changes
                    this.$emit('reRenderComponent', 1)
                } catch (error) {
                    this.$emit('loading', false);
                    if(error.response.status === 422) {
                        let errors = error.response.data.errors;
                        for (let field in errors) this.errorMessages[field] = errors[field]
                    }
                    if (error.response.status === 401) {
                      await this.$store.dispatch('logout');
                    } else if (error.response.status === 433) {
                      await this.$store.dispatch('unverify');
                    } else if (error.response) {
                        this.showError(error.response.data.message);
                    } else if (error.request) {
                        this.showError(error.request);
                    } else {
                        this.showError(error.message);
                    }
                }
            }
        },
    }
})
</script>
