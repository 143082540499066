<template>
  <div class="ma-1">
    <v-list id="transparent-background">
      <v-list-item>
        <v-row>
          <v-col cols="12" xs="12" md="4">
            {{$t('amountToCharge')}}:
          </v-col>
          <v-col cols="12" xs="12" md="4">
            <v-form
            lazy-validation
            ref="balanceValidation"
            >
              <v-text-field
              v-model="amount"
              outlined
              :label="$t('amount')"
              :rules="balanceRule"
              ></v-text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-list-item>

      <v-list-item>
        <v-row v-if="showSavedPaymentMethod" class="mt-2">
          <v-col cols="12" xs="12" md="4">
            {{$t('paymentMethod')}}:
          </v-col>
          <v-col cols="12" xs="12" md="8">
            <strong>{{ payment_method === null ? saved_payment_method : (payment_type === 'card' ? payment_method.credit_card_number_formatted : payment_type)}}</strong>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>

    <div>
      <v-row class="my-3">
        <v-col cols="12" sm="12">
          <vue-recaptcha :sitekey="reCaptcha_site_key"></vue-recaptcha>

          <div v-if="payment_type === 'PayPal'">
            <template>
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post">
                <input type="hidden" name='cmd' value='_xclick'>
                <input type="hidden" name='business' :value='paypal.business_email'>
                <input type="hidden" name='amount' :value='amount'>
                <input type="hidden" name='notify_url' :value='paypal.callback'>
                <input type="hidden" name='item_number' :value='paypal.i_customer'>
                <input type="hidden" name='currency_code' value='USD'>
                <input type="hidden" name='return' :value='paypal.return_url'>
                <input type="hidden" name='custom' :value='paypal.transaction_id'>
                <input ref="paypalButton" hidden type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_paynowCC_LG.gif" border="0" alt="PayPal - The safer, easier way to pay online!">
              </form>
            </template>

            <v-btn
                color="success"
                dark
                block
                @click="payPaypalBalance">
              {{ $t("payNowWithPaypalBtn") }}
            </v-btn>
          </div>

          <div v-else>
            <v-btn
            color="success"
            dark
            block
            @click="payBalance">
              {{ $t("payNowBtn") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// importing Api requests
import Api from '@/api/Api';
// importing store modules
import store from '@/store/modules';

import {VueRecaptcha} from 'vue-recaptcha';

export default {
  components: { VueRecaptcha },
  name: "stepper-two",
  // data received from parent component
  props: ['payment_method', 'payment_type', 'saved_payment_method', 'balance'],
  // initializing data
  data: function() {
    return {
      showSavedPaymentMethod: true,
      amount: 0.00,
      minimumPayment: 0.00,
      paypal: {
        callback: process.env.VUE_APP_PAYPAL_CALLBACK,
        business_email: process.env.VUE_APP_PAYPAL_BUSINESS_EMAIL,
        return_url: process.env.VUE_APP_PAYPAL_RETURN_URL,
        transaction_id: null,
        i_customer: 'c' + store.getters.StateUser.name,
      },
      balanceRule: [
        value => !!value && value >= this.minimumPayment || this.$i18n.t('balanceRule', {balance: this.minimumPayment})
      ],
      reCaptcha_site_key: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    }
  },
  watch: {
    // watching when the payment type is changed
    payment_type(value) {
      // if value is equal to paypal, the minimum amount to be paid is set 
      // to 25.
      if(value === 'PayPal') {
        this.amount = 25;
        this.minimumPayment = 25;
        this.registerTransaction();
      // if value is a credit card, paypal buttons and input value won't appear
      } else {
        this.amount = this.balance;
        this.minimumPayment = 5;
      }
    },
  },
  methods: {
    // making a request to pay the customer's balance
    async payBalance() {
      try {
        if(this.$refs.balanceValidation.validate()) {
          let reCaptcha_response = window.grecaptcha.getResponse();
          if (!reCaptcha_response) {
            this.$toast.error('Please complete the reCAPTCHA.', {
              timeout: 4000
            });
            return;
          }
          this.$emit('loading', true);
          // initializing the form data and appending the required information
          let form_data = new FormData();
          form_data.append('balance', this.amount);
          form_data.append('g-recaptcha-response', reCaptcha_response);
          if (this.payment_method === null) {
            form_data.append('payment_method', false);
          } else {
            form_data.append('payment_method', true);
            for (let key in this.payment_method) form_data.append(key, this.payment_method[key]);
          }
          // making the customer payment in porta
          let response = await Api.makeCustomerPayment(
              this.$store.getters.i_customer,
              this.$store.getters.token,
              form_data,
          );
          this.$emit('loading', false);
          await this.$router.push({
            name: 'Payment Completed',
            params:
                {
                  paymentReceipt: response.data,
                  paymentInformation: this.saved_payment_method
                }
          });
        }
      } catch (error) {
        let errorMsg = '';
        this.$emit('loading', false);
        if (error.response.status === 401) {
          await this.$store.dispatch('logout');
        } else if (error.response.status === 433) {
          await this.$store.dispatch('unverify');
        } else if (error.response) {
          errorMsg = error.response.data.message;
        } else if (error.request) {
          errorMsg = error.request;
        } else {
          errorMsg = error.message;
        }
        await this.$router.push({
          name: 'Payment Error',
          params:
              {
                balance: this.amount,
                errorMsg: errorMsg,
                paymentInformation: this.saved_payment_method
              }
        });
      }
    },
    // validating input balance when paying with paypal
    payPaypalBalance () {
      if(this.$refs.balanceValidation.validate()) {
        this.$refs.paypalButton.click();
      }
    },
    // registering a transaction for paypal
    async registerTransaction() {
      try {
        // initializing the form data and appending the required information
        let form_data = new FormData();
        form_data.append('balance', this.amount);

        // making the request to register a transaction
        let response = await Api.registerTransaction(
          this.$store.getters.i_customer,
          this.$store.getters.token,
          form_data
        );
        // setting the transaction id once a response is received
        this.paypal.transaction_id = `tr_id=[${response.data.transaction.i_payment_transaction}]`;
      } catch (error) {
        if (error.response.status === 401) {
          await this.$store.dispatch('logout');
        } else if (error.response.status === 433) {
          await this.$store.dispatch('unverify');
        } else if (error.response) {
          this.$emit("error-message", error.response.data.message);
        } else if (error.request) {
          this.$emit("error-message", error.request);
        } else {
          this.$emit("error-message", error.message);
        }
      }
    },
  },
};
</script>
