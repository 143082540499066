<template>
  <div>
    <v-overlay
        :value="loading"
    >
      <v-progress-circular indeterminate size="100">
        {{ $t('loadingBtn') }}
      </v-progress-circular>
    </v-overlay>
    <v-container style="margin-top: 150px;">
      <v-layout justify-center>
        <v-flex xs12 sm8 md4>
          <v-alert
              v-if="fromCaptive"
              shaped
              type="warning"
              variant="tonal"
          >{{ $t('suspendedAlert') }}</v-alert>
          <verify
              @loading="val => loading = val"
              @error-message="showError"
              v-bind:email="email"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
// importing child components
import Verify from '@/components/Verify/VerifyCard.vue';

export default {
  name: 'verify-',
  props: ['fromCaptive', 'email'],
  // child components that are being displayed
  components: {
    Verify
  },
  // initializing the data
  data: () => ({
    loading: false
  }),
  methods: {
    // showing the error message component with the message passed
    showError(msg) {
      this.$toast.error(msg, {
        timeout: 4000
      });
    }
  }
};
</script>