import Api from '@/api/Api';
import router from '@/router';

// initializing state components to null
const state = {
    verified: false,
    authenticated: null,
    user: null,
    email: null,
    i_customer: null,
    token: null,
    expires_at: null,
};
// initializing getters with state component
const getters = {
    isVerified: state => state.verified,
    email: state => state.email,
    isAuthenticated: state => !!state.user,
    StateUser: state => state.user,
    i_customer: state => state.i_customer,
    token: state => state.token,
    expires_at: state => state.expires_at,
    isCorporativeCustomer: state => state.user ? state.user.name.includes("B1") : false,
};
// actions
const actions = {
    // login action validates a user with the API
    async login({commit}, User) {
        let response = await Api.validateCustomer(User);
        await commit('setUser', response.data)
    },
    async verify({commit, state}, data) {
        data.append('i_customer', state.i_customer);
        let response = await Api.verifyTwoFactor(data, state.token);
        await commit('verifyUser', response.data);
    },
    async resendCode({state}) {
        let data = new FormData()
        data.append('i_customer', state.i_customer)
        await Api.resendTwoFactorCode(data, state.token);
    },
    // logout action reset all the state components
    async logout({commit}) {
        let user = null
        await commit('logout', user)
        // sending the customer to login after is logged out
        await router.push('/login')
    },
    unverify({commit}) {
        commit('unverifyUser');
    }
};
// mutations
const mutations = {
    // sets the customer state 
    setUser(state, user) {
        let expires_at = new Date();
        let hours = 3; // the state will expire in 3 hours

        state.email = user.customer.email;
        state.user = user.customer;
        state.i_customer = user.customer.i_customer;
        state.token = user.customer.token;
        state.expires_at = expires_at.getTime() + (hours * 60 * 60 * 1000);
    },
    // sets the customer state to null
    logout(state) {
        state.verified = false;
        state.email = null;
        state.user = null;
        state.i_customer = null;
        state.token = null;
        state.expires_at = null;
    },
    verifyUser(state) {
        state.verified = true;
    },
    unverifyUser(state) {
        state.verified = false;
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};
